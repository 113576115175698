import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import Modal from 'components/common/Modal';
import { CloseIcon, MessageIcon } from 'components/common/Icon';
import BaseForm from 'components/forms/BaseForm';
import { H4Text, Text } from 'components/common/Typography';
import withPostHog from "hooks/withPosthog";

type FeedbackModalProps = {
    UserStore?: any;
    userEvents?: any;
    onClose: () => void;
}

type FeedbackModalState = {
    message: string;
    isLoading: boolean;
}

class FeedbackModal extends React.Component <FeedbackModalProps, FeedbackModalState> {
    state = {
        message: undefined,
        isLoading: false
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { UserStore, userEvents, onClose } = this.props;
        const { message } = this.state;

        this.setState({ isLoading: true }, () => {
            UserStore.sendFeedback({ message })
                .then(() => {
                    toast.success(
                        "Thank you for sending feedback!", 
                        { theme: 'dark', position: 'bottom-right', icon: false }
                    );

                    if (userEvents) {
                        userEvents?.posthog.capture("feedback", { message: message })
                    }

                    onClose();
                })
                .catch(() => {
                    toast.error(
                        "Failed to send feedback, please try again", 
                        { theme: 'dark', position: 'bottom-right', icon: false }
                    );
                });
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ message: value });
    };

    render() {
        const { message, isLoading } = this.state;
        const { onClose } = this.props;

        const config = [
            {
                labelText: "Feedback for Adapter",
                fieldId: "message",
                type: "textarea",
                value: message,
                required: true,
                errorText: message !== undefined && message?.length === 0 ? "Message is required" : undefined
            }
        ];

        return (
            <Modal onClose={onClose}>
                <div className="onboarding-modal">

                    <div className="onboarding-modal-head">
                        <MessageIcon color="#5C33CC" />
                        <CloseIcon
                            onClick={onClose}
                            style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                        />
                    </div>

                    <div className="onboarding-modal-body">
                        <H4Text>Provide Feedback</H4Text>

                        <Text fontSize='s'>
                            This is an early release of Adapter and we are always looking for ways to improve the product. 
                            Please do NOT include any personally identifying information in your feedback (e.g., name, birthday, SSN, etc).
                        </Text>

                        <BaseForm
                            config={config}
                            isLoading={isLoading}
                            buttonText="Submit"
                            onChange={this.onChangeHandler}
                            onSubmit={this.onSubmitHandler}
                            style={{ minWidth: '50%' }}
                        />
                    </div>

                    <div className="onboarding-modal-foot"></div>
                </div>
            </Modal>
        );
    }
}

export default withPostHog(inject('UserStore')(observer(FeedbackModal)));