import React from 'react';

import { Text } from '../Typography';
import './Button.scss';

type ButtonProps = {
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  children: any;
  size?: "2xl" | "small";
  style?: any;
  cls?: string;
};

const Button = ({ children, disabled, type, size, onClick, style, cls }: ButtonProps) => (
  <button
    className={`adapter-button ${size && 'size-' + size} ${cls}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
    style={style}
  >
    <Text>{children}</Text>
  </button>
);

export default Button;