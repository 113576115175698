import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";
import { usePostHog } from 'util/posthogWrapper';

import withRouter from 'hooks/withRouter';
import StepHeader from './StepHeader';
import SafeguardsModal from '../modals/SafeguardsModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import { GoogleIcon, CaretRightIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';
import { Text, H3Text, LINK } from 'components/common/Typography';
import Card from 'components/common/Card';

const AccountOption = ({ name, id, onClick, Icon, showCaret = false, comingSoon = false, requested = false }) => (
    <div
        className={`connect-account-option ${comingSoon ? 'coming-soon' : ''} ${requested ? 'requested' : ''}`}
        onClick={() => onClick(id)}
    >
        <div><Icon /></div>
        <div className="flex-1">
            <Text>{name}</Text>
        </div>
        {showCaret && <div><CaretRightIcon /></div>}
    </div>
);

const Connect = ({ OnboardingStore, router }: { OnboardingStore?: any, router?: any }) => {
    const [safeguardsOpen, setSafeguardsOpen] = useState(false);
    const [dataHandlingOpen, setDataHandlingOpen] = useState(false);
    const [requestedThirdParties, setRequestedThirdParties] = useState({});

    const toastOptions = {
        theme: 'dark',
        position: 'bottom-right',
        icon: false
    };

    const posthog = usePostHog();

    const requestThirdParty = (vendor) => {
        OnboardingStore.requestVendor(vendor)
            .then(() => {
                posthog.capture("vendor requested", { request: vendor });
                setRequestedThirdParties(Object.assign({}, requestedThirdParties, { [vendor]: true }))
            })
            .catch(() => {
                // @ts-ignore
                toast.error("Failed to send request. Please contact customer support if you continue to encounter problems.", toastOptions);
            });
    };

    return (
        <OnboardingWrapper>
            <StepHeader
                progress={60}
                text='Connection'
                onClick={() => router.navigate('/onboarding/details') } 
            />

            <Card>
                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <H3Text cls='adapter-font-color-primary-900'>
                            Connect an Account
                        </H3Text>

                        <Text>
                            Adapter unlocks valuable activity data from the tools you use every day. It imports your data history
                            into Adapter and gets any new data when it is created. You can set filters for the type of data that
                            is collected and revoke access to an account at any time.
                        </Text>

                        <Text>
                            <LINK onClick={() => setSafeguardsOpen(true)}>Learn more with our data FAQ&nbsp;&rarr;</LINK>
                        </Text>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <AccountOption
                            name="Google Mail &amp; Calendar"
                            id="google"
                            Icon={GoogleIcon}
                            onClick={() => router.navigate('/onboarding/controls') } 
                            showCaret
                        />

                        <Text fontSize='l'>Coming Soon...</Text>

                        <Text>Let us know which accounts you would like us to activate next.</Text>

                        <div className='coming-soon-wrapper'>
                            <AccountOption
                                name="X"
                                id="x"
                                Icon={() => (
                                    <div style={{ width: 44, height: 44, padding: 1.38, background: '#F2F2F0', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ width: 33.23, height: 33.23, position: 'relative' }}>
                                            <div style={{ width: 29.08, height: 29.08, left: 2.08, top: 2.08, position: 'absolute', borderRadius: 38.77 }} />
                                            <div data-svg-wrapper style={{ left: 8.31, top: 8.31, position: 'absolute' }}>
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.732664 0.192383L7.14833 8.78506L0.692139 15.7693H2.14598L7.79674 9.6527L12.3634 15.7693H17.3075L10.5322 6.69461L16.5401 0.192383H15.0888L9.88379 5.82443L5.67929 0.192383H0.732664ZM2.87038 1.26298H5.14233L15.1723 14.6962H12.9004L2.87038 1.26298Z" fill="#727271" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['x']}
                            />

                            <AccountOption
                                name="Outlook"
                                id="outlook"
                                Icon={() => (
                                    <div style={{ width: 44, height: 44, padding: 1.38, background: '#F2F2F0', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ width: 33.23, height: 33.23, position: 'relative' }}>
                                            <div style={{ width: 29.08, height: 29.08, left: 2.08, top: 2.08, position: 'absolute', borderRadius: 38.77 }} />
                                            <div data-svg-wrapper style={{ left: 6, top: 6, position: 'absolute' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                                    <path d="M12.9342 0.5V22.4999L0 19.7685L0.0652975 3.00879L12.9342 0.5ZM6.16328 15.5792C6.23125 15.6071 7.4639 15.3301 7.60916 15.281C10.4303 14.3361 10.3463 7.50022 6.60437 7.5254C3.029 7.54925 2.49996 13.8285 5.31175 15.171C5.5223 15.2718 5.80748 15.2691 6.04068 15.3513C6.13663 15.3857 6.14862 15.5739 6.16328 15.5792Z" fill="#727271" />
                                                    <path d="M13.8669 16.9339V11.2351C14.396 11.4167 15.0476 12.3046 15.6353 12.1429L21.9958 7.92188V16.205C21.9958 16.4356 21.3562 16.9339 21.1296 16.9339H13.8669Z" fill="#727271" />
                                                    <path d="M13.8669 5.33711C13.9669 5.20193 13.9829 5.19398 14.1388 5.19265C16.5495 5.1847 18.9775 5.35699 21.3935 5.27349C21.744 5.32916 21.9212 5.68434 21.9998 5.99578L15.4647 10.3772L13.8669 9.51179V5.33711Z" fill="#727271" />
                                                    <path d="M5.64643 9.55369C6.06753 9.09116 7.03367 9.13622 7.47743 9.57224C8.24101 10.3224 8.27832 13.3255 7.07765 13.6939C4.81622 14.3897 4.65631 10.6391 5.64643 9.55369Z" fill="#727271" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['outlook']}
                            />

                            <AccountOption
                                name="iCloud"
                                id="icloud"
                                Icon={() => (
                                    <div data-svg-wrapper>
                                        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="44" height="44" rx="22" fill="#F2F2F0" />
                                            <path d="M28.8144 18.8216C28.7351 18.8679 26.8465 19.8446 26.8465 22.0101C26.9355 24.4799 29.2298 25.346 29.2692 25.346C29.2298 25.3922 28.9228 26.5258 28.0133 27.7141C27.2916 28.7377 26.4905 29.7697 25.274 29.7697C24.1168 29.7697 23.7015 29.0875 22.3663 29.0875C20.9324 29.0875 20.5267 29.7697 19.4289 29.7697C18.2124 29.7697 17.352 28.6824 16.5909 27.6684C15.6021 26.3413 14.7616 24.2587 14.732 22.259C14.712 21.1993 14.93 20.1577 15.4834 19.273C16.2645 18.0378 17.659 17.1994 19.1819 17.1717C20.3487 17.135 21.3872 17.9182 22.0993 17.9182C22.7817 17.9182 24.0575 17.1717 25.501 17.1717C26.1241 17.1723 27.7857 17.3472 28.8144 18.8216ZM22.0006 16.9601C21.7929 15.9924 22.3663 15.0248 22.9004 14.4075C23.5828 13.661 24.6606 13.1543 25.59 13.1543C25.6494 14.122 25.2733 15.071 24.6012 15.7622C23.9982 16.5087 22.9597 17.0707 22.0006 16.9601Z" fill="#727271" />
                                        </svg>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['icloud']}
                            />

                            <AccountOption
                                name="Facebook"
                                id="facebook"
                                Icon={() => (
                                    <div data-svg-wrapper>
                                        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="44" height="44" rx="22" fill="#F2F2F0" />
                                            <g clipPath="url(#clip0_186_2220)">
                                                <path d="M31.9998 22.4999C31.9998 16.9771 27.5227 12.5 21.9999 12.5C16.4771 12.5 12 16.9771 12 22.4999C12 27.4911 15.6568 31.6281 20.4374 32.3783V25.3905H17.8984V22.4999H20.4374V20.2968C20.4374 17.7906 21.9304 16.4062 24.2145 16.4062C25.3083 16.4062 26.453 16.6015 26.453 16.6015V19.0624H25.1921C23.9499 19.0624 23.5624 19.8333 23.5624 20.6249V22.4999H26.3358L25.8924 25.3905H23.5624V32.3783C28.343 31.6281 31.9998 27.4911 31.9998 22.4999Z" fill="#727271" />
                                                <path d="M25.8925 25.3905L26.3359 22.4999H23.5624V20.625C23.5624 19.8341 23.9499 19.0625 25.1921 19.0625H26.453V16.6016C26.453 16.6016 25.3087 16.4062 24.2146 16.4062C21.9304 16.4062 20.4375 17.7906 20.4375 20.2968V22.4999H17.8984V25.3905H20.4375V32.3784C21.4728 32.5403 22.5271 32.5403 23.5624 32.3784V25.3905H25.8925Z" fill="#FCFCFA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_186_2220">
                                                    <rect width="19.9998" height="19.9998" fill="white" transform="translate(12 12.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['facebook']}
                            />

                            <AccountOption
                                name="LinkedIn"
                                id="linkedin"
                                Icon={() => (
                                    <div style={{ width: 44, height: 44, padding: 1.38, background: '#F2F2F0', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ width: 33.23, height: 33.23, position: 'relative' }}>
                                            <div style={{ width: 29.08, height: 29.08, left: 2.08, top: 2.08, position: 'absolute', borderRadius: 38.77 }} />
                                            <div data-svg-wrapper style={{ left: 9.35, top: 8.31, position: 'absolute' }}>
                                                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.48845 1.94961C4.48845 2.92011 3.64725 3.70685 2.60958 3.70685C1.57191 3.70685 0.730713 2.92011 0.730713 1.94961C0.730713 0.979122 1.57191 0.192383 2.60958 0.192383C3.64725 0.192383 4.48845 0.979122 4.48845 1.94961Z" fill="#727271" />
                                                    <path d="M0.987652 4.99849H4.19939V14.7308H0.987652V4.99849Z" fill="#727271" />
                                                    <path d="M9.37029 4.99849H6.15855V14.7308H9.37029C9.37029 14.7308 9.37029 11.667 9.37029 9.75127C9.37029 8.60145 9.7629 7.44659 11.3294 7.44659C13.0998 7.44659 13.0892 8.95132 13.0809 10.1171C13.0701 11.6408 13.0959 13.1958 13.0959 14.7308H16.3076V9.59432C16.2804 6.31451 15.4258 4.80324 12.6141 4.80324C10.9444 4.80324 9.90937 5.56129 9.37029 6.24712V4.99849Z" fill="#727271" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['linkedin']}
                            />

                            <AccountOption
                                name="Uber"
                                id="uber"
                                Icon={() => (
                                    <div style={{ width: 44, height: 44, padding: 1.38, background: '#F2F2F0', borderRadius: 100, justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                                        <div style={{ width: 33.23, height: 33.23, position: 'relative' }}>
                                            <div style={{ width: 29.08, height: 29.08, left: 2.08, top: 2.08, position: 'absolute', borderRadius: 38.77 }} />
                                            <div data-svg-wrapper style={{ left: 6, top: 6, position: 'absolute' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                                                    <g clipPath="url(#clip0_652_499)">
                                                        <path d="M0.5 11.2962H7.97223V13.2652L8.1237 13.4166H13.7784L13.9298 13.2652V7.81247C13.9298 7.77915 13.7965 7.65899 13.8288 7.56003H8.1237L7.97223 7.7115V9.78151H0.600976C0.762538 5.15983 4.36335 1.11675 8.85981 0.219071C19.123 -1.83074 25.742 10.9963 18.1223 18.1656C11.7043 24.2049 1.14827 19.973 0.5 11.2962Z" fill="#727271" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_652_499">
                                                            <rect width="20.9222" height="21" fill="white" transform="translate(0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                onClick={requestThirdParty}
                                comingSoon
                                requested={requestedThirdParties['uber']}
                            />
                        </div>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </Card>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}
            {safeguardsOpen && <SafeguardsModal onClose={() => setSafeguardsOpen(false)} />}
        </OnboardingWrapper>
    );
}

export default withRouter(inject('OnboardingStore')(observer(Connect)));