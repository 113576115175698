import React from "react";
import { observer, inject } from "mobx-react";
import { H1Text, Text, LINK } from "components/common/Typography";
import config from "config";

type AccessDeniedProps = {
  AuthStore?: any;
}

class AccessDeniedPage extends React.Component<AccessDeniedProps, {}> {
  handleSignout = () => {
    const { AuthStore } = this.props;
    const auth0Domain = window.REACT_APP_AUTH0_DOMAIN ?? config.auth0.domain;

    AuthStore.logout().then(() => {
      window.location.href = `https://${auth0Domain}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}`;
    });
  }

  render() {

    return (
      <main className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">
            <div className="flex-1"></div>

            <H1Text>Members Only</H1Text>

            <div>
              <Text>Sorry, you don&apos;t have permission to view this page.</Text>

              <Text>
                Please contact <LINK href="mailto:support@adapter.com">support@adapter.com</LINK> if you continue to encounter problems.
              </Text>
            </div>

            <div>
              <Text fontSize="s">If you just created an Adapter account, please verify your email before logging in.</Text>

              <Text fontSize="s">
                If you think this is a mistake, please <LINK onClick={this.handleSignout}>click here</LINK> to delete your browser cookies and try again.
              </Text>
            </div>

            <div className="flex-1"></div>
          </div>
        </div>
      </main >
    );
  }
}

export default inject('AuthStore')(observer(AccessDeniedPage));