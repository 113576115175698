/**
 * @fileoverview Utilities for simplifying communication between the web application and native 
 * iOS app.
 * 
 * This module provides standardized methods for creating promise-based calls to native functions
 * and includes robust error handling and callback management to prevent naming collisions.
 */

/**
*  Generic error class that can be extended by specific native bridge implementations
*  to provide consistent error handling across the application.
*/
export class NativePromiseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
  
/**
* Creates a unique callback function on the window object for handling native responses.
* 
* This utility function generates a temporary callback with a unique name to avoid
* naming collisions, which is essential when multiple concurrent native calls are made.
* The callback automatically cleans itself up after being invoked once.
* 
* @param handlerType - The type of message handler (e.g., 'storage', 'location')
* @param errorClass - The error class to instantiate when an error occurs
* @param resolve - The Promise resolve function to call on success
* @param reject - The Promise reject function to call on error
* @returns A unique callback name that can be sent to native code
*/
export function buildCallback<T>(
  handlerType: MessageHandlerType,
  errorClass: new (message: string) => NativePromiseError,
  resolve: (value: unknown) => void,
  reject: (reason?: any) => void
): string {
  // Create a unique callback name with random component
  const randomId = Math.floor(Math.random() * 10000);
  const callbackName = `_${handlerType}_callback_${Date.now()}_${randomId}`;
  
  // Create temporary callback function
  (window as any)[callbackName] = (result: T, errorMessage?: string) => {
    delete (window as any)[callbackName]; // Clean up
    if (errorMessage) {
      reject(new errorClass(errorMessage));
    } else {
      resolve(result);
    }
  };

  return callbackName
}

/**
* TypeScript interface extensions for the global Window object to support
* WebKit message handlers used for native iOS communication.
* 
* This declaration merges with the global Window interface to provide
* type safety and autocompletion when accessing WebKit message handlers
* throughout the application. It defines the structure of the webkit
* property and its nested messageHandlers for various bridge operations.
* 
* Note that adding a new handler in here is not enough, you need to register
* the handler on the iOS app as well:
* 
* https://github.com/Adapter-Group/adapter-ios/blob/main/Adapter/WebviewLoading/WebViewWrapper.swift#L40
* 
*/
declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        storage?: {
          postMessage: (message: unknown) => void;
        },
        location?: {
          postMessage: (message: unknown) => void;
        },
        signoutRequest?: {
          postMessage: (message: unknown) => void;
        };
        // add more here and update MessageHandlerType
      };
    };
  }
}

/**
 * A string-backed enum used to provide type safety to the message handlers.
 * 
 * Note: The values in th enum MUST MATCH the values in the messageHandlers declaration.
 */
export enum MessageHandlerType {
  Storage = "storage",
  Location = "location",
  signoutRequest = "signoutRequest"
  // Add more here and update global.webkit.messageHandlers 
}
