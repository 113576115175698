import React from 'react';
import { inject, observer } from 'mobx-react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import Pill from 'components/common/Pill';
import Card from 'components/common/Card';
import { Text } from 'components/common/Typography';
//import HotelOption from './options/hotel/HotelOption';
//import RestaurantOption from './options/restaurant/RestaurantOption';

import './MapView.scss';

const BEST_MATCH_COLOR = '#5C33CC';
const MAP_LOCATION_COLOR = '#EF6820';
const ZOOM_SCALE = 10;

const createIcon = (index) => {
    let label = index;
    if (Number.isInteger(index)) {
        label += 1;
    }

    return L.divIcon({
        html: `
<div data-before="${label}">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3L4 22" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</div>
        `,
        className: `svg-icon ${index === 0 ? 'best-match' : ''}`,
        iconSize: [24, 24],
        iconAnchor: [12, 24]
    });
}

export type MapOption = {
    timestamp?: number;
    score?: number;
    option?: {
        gps_coordinates?: {
            lat: number;
            lng: number;
        }
    }
}

type MapViewProps = {
    options: MapOption[];
    type: 'restaurant' | 'hotel';
    TelemetryStore?: any;
};

class MapView extends React.Component<MapViewProps, {}> {
    findCenterLatLng = () => {
        const { options, TelemetryStore } = this.props;

        if (TelemetryStore.lastKnownLocation) {
            const location = TelemetryStore.lastKnownLocation?.option;

            if (location && location?.gps_coordinates) {
                const { lat, lng } = location.gps_coordinates;
                return [lat, lng];
            }
        }

        if (options.length === 0) {
            return [40, -74];
        }

        let totalLat = 0;
        let totalLng = 0;

        for (const point of options) {
            const lat = point.option?.gps_coordinates?.lat;
            const lng = point.option?.gps_coordinates?.lng;

            if (lat && lng) {
                totalLat += lat;
                totalLng += lng;
            }
        }

        if (totalLat === 0 && totalLng === 0) {
            return [40, -74.2];
        }

        return [
            totalLat / options.length,
            totalLng / options.length
        ];
    }

    generateMarker = (data, type, index) => {
        const option = data.option;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (lat && lng) {
            let card;
            switch (type) {
                case 'restaurant':
                // card = (
                //     <RestaurantOption
                //         key={i}
                //         option={option}
                //         index={i}
                //         score={option.score}
                //         allScores={options.map(r => r.score)}
                //     />
                // );
                // break;
                case 'hotel':
                //     card = (
                //         <HotelOption
                //             key={i}
                //             option={option}
                //             index={i}
                //             score={option.score}
                //             allScores={options.map(r => r.score)}
                //         />
                //     );
                // break;
                case 'current-location':
                    card = (
                        <Card>
                            <Text>Your location was last recorded: {new Date(data.timestamp).toLocaleString()}</Text>
                        </Card>
                    );
                    break;

                default:
                    card = (
                        <Card>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </Card>
                    );
                    break;
            }

            return (
                <Marker
                    key={index}
                    position={[lat, lng]}
                    // @ts-ignore
                    icon={createIcon(index)}
                    data-before={index}
                >
                    <Popup>
                        {card}
                    </Popup>
                </Marker>
            );
        }
    }

    definePoints = () => {
        const { options, type, TelemetryStore } = this.props;
        const points = [];

        if (TelemetryStore.lastKnownLocation) {
            points.push(this.generateMarker(TelemetryStore.lastKnownLocation, 'current-location', 'Me'));
        }

        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            const marker = this.generateMarker(option, type, i);

            if (marker) {
                points.push(marker);
            }
        }

        return points;
    };

    render() {
        const center = this.findCenterLatLng();

        return (
            <MapContainer
                // @ts-ignore
                center={center}
                zoom={ZOOM_SCALE}
                scrollWheelZoom={false}
                className='adapter-mapview flex-1'
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {this.definePoints()}
            </MapContainer>
        );
    }
}

export default inject("TelemetryStore")(observer(MapView));