import { useState } from 'react';

import withRouter from 'hooks/withRouter';
import AccountDisplay from 'pages/settings/AccountDisplay';
import GrantDataAccessModal from '../modals/GrantDataAccessModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import OnboardingWrapper from '../index';
import { Text, H3Text, LINK } from 'components/common/Typography';
import StepHeader from './StepHeader';
import Card from 'components/common/Card';
import SafeguardsModal from '../modals/SafeguardsModal';

const DataControls = ({ router }: { router?: any; }) => {
    const [grantDataAccessOpen, setGrantDataAccessOpen] = useState(false);
    const [callbackFn, setCallbackFn] = useState(undefined);
    const [dataHandlingOpen, setDataHandlingOpen] = useState(false);

    return (
        <OnboardingWrapper>
            <StepHeader
                progress={80}
                text='Controls'
                onClick={() => router.navigate('/onboarding/connect') } 
            />

            <Card>
                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <H3Text cls='adapter-font-color-primary-900'>
                            Set your data controls
                        </H3Text>

                        <Text>
                            Adapter gives you control over what parts of your life you want to share. It automatically sorts your 
                            imported activity data and only keeps data from the categories you&apos;ve selected here.* 
                            The more data you share, the more insights Adapter can learn about you. 
                        </Text>

                        <Text>
                            *Note: data categories currently only apply to historical email data and not calendar data 
                            or ongoing email data.
                        </Text>

                        <Text>
                            <LINK onClick={() => setDataHandlingOpen(true)}>Learn more with our data FAQ&nbsp;&rarr;</LINK>
                        </Text>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <AccountDisplay
                            hideIcon
                            buttonText='Connect Google Account'
                            beforeSubmit={(fn) => {
                                setGrantDataAccessOpen(true);

                                // https://stackoverflow.com/a/55621325/551436
                                // yes, function-returns-inner-function looks weird but React hooks are kinda weird
                                setCallbackFn(() => () => fn());
                            }}
                        />

                        <div className="flex-1"></div>
                    </div>
                </div>
            </Card>

            {dataHandlingOpen && <SafeguardsModal onClose={() => setDataHandlingOpen(false)} />}

            {grantDataAccessOpen && (
                <GrantDataAccessModal
                    onContinue={() => callbackFn()}
                    onClose={() => setGrantDataAccessOpen(false)}
                />
            )}
        </OnboardingWrapper >
    );
}

export default withRouter(DataControls);