import React, { useEffect, useRef } from 'react';
import { Rive, RuntimeLoader} from '@rive-app/canvas';
// @ts-ignore
import riveWASMResource from '@rive-app/canvas/rive.wasm';

RuntimeLoader.setWasmUrl(riveWASMResource)

const RiveSpinner = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const riveRef = useRef<Rive | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      riveRef.current = new Rive({
        src: '/riv/spinner.riv',
        canvas: canvasRef.current,
        autoplay: true,
        onLoad: () => {
          if (riveRef.current) {
            riveRef.current.resizeDrawingSurfaceToCanvas();
          }
        },
      });
    }

    return () => {
      if (riveRef.current) {
        riveRef.current.cleanup();
      }
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (riveRef.current) {
        riveRef.current.resizeDrawingSurfaceToCanvas();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <canvas ref={canvasRef} width="80" height="80" />;
};

export default RiveSpinner;