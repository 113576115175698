import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import Modal from 'components/common/Modal';
import { CloseIcon, EmailIcon } from 'components/common/Icon';
import BaseForm from 'components/forms/BaseForm';
import { H4Text, Text } from 'components/common/Typography';
import withPostHog from "hooks/withPosthog";

type ContactSupportModalProps = {
    UserStore?: any;
    userEvents?: any;
    onClose: () => void;
}

type ContactSupportModalState = {
    message: string;
    isLoading: boolean;
}

class ContactSupportModal extends React.Component <ContactSupportModalProps, ContactSupportModalState> {
    state = {
        message: undefined,
        isLoading: false
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { UserStore, userEvents, onClose } = this.props;
        const { message } = this.state;

        this.setState({ isLoading: true }, () => {
            UserStore.sendFeedback({ message })
                .then(() => {
                    toast.success(
                        "Thank you for contacting customer support!", 
                        { theme: 'dark', position: 'bottom-right', icon: false }
                    );

                    if (userEvents) {
                        userEvents?.posthog.capture("customer-support", { message: message })
                    }

                    onClose();
                })
                .catch(() => {
                    toast.error(
                        "Failed to contact customer support, please try again", 
                        { theme: 'dark', position: 'bottom-right', icon: false }
                    );
                });
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ message: value });
    };

    render() {
        const { message, isLoading } = this.state;
        const { onClose } = this.props;

        const config = [
            {
                labelText: "Message for Customer Support",
                fieldId: "message",
                type: "textarea",
                value: message,
                required: true,
                errorText: message !== undefined && message?.length === 0 ? "Message is required" : undefined
            }
        ];

        return (
            <Modal onClose={onClose}>
                <div className="onboarding-modal">

                    <div className="onboarding-modal-head">
                        <EmailIcon color="#5C33CC" />
                        <CloseIcon
                            onClick={onClose}
                            style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                        />
                    </div>

                    <div className="onboarding-modal-body">
                        <H4Text>Contact Customer Support</H4Text>

                        <Text fontSize='s'>
                            We&apos;re committed to ensuring you have the best possible experience with our product. 
                            Enter your request below, please include a detailed description of your issue.
                        </Text>

                        <BaseForm
                            config={config}
                            isLoading={isLoading}
                            buttonText="Submit"
                            onChange={this.onChangeHandler}
                            onSubmit={this.onSubmitHandler}
                            style={{ minWidth: '50%' }}
                        />
                    </div>

                    <div className="onboarding-modal-foot"></div>
                </div>
            </Modal>
        );
    }
}

export default withPostHog(inject('UserStore')(observer(ContactSupportModal)));